import React from "react"
//components
import { useStaticQuery, graphql } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from "../components/Seo"
import CookiePopUp from '../components/CookiePopUp'
import { Link } from 'gatsby'
//stylesheet
import '../components/NotFoundPage.scss';
//image
import SvgElement from '../images/telescope.svg'

export default function NotFoundPage() {

  const data = useStaticQuery(graphql`{
  wpPage {  
    translations {
      uri
    }
  }
  allWpMenu {
    menus: nodes {
      name
      locations
      menuItems {
        nodes {
          databaseId
          parentDatabaseId
          label
          cssClasses
          parentId
          path
          childItems {
            nodes {
              databaseId
              parentDatabaseId
              cssClasses
              label
              path
              order
              parentId
              childItems {
                nodes {
                  databaseId
                  parentDatabaseId
                  cssClasses
                  label
                  parentId
                  path
                }
              }
            }
          }
        }
      }
    }
  }
  allWp {
    nodes {
      optionsPage {
        footerOptions: options_page {
          fieldGroupName
          contactFooter {
            address
            email
            phone
            workingHours
          }
        }
      }
    }
  }
}`)

  const { wpPage: { translations }, allWpMenu, allWp: { nodes: [optionsPage] } } = data;

  //getting url from the page
  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  //in this object we set the language, depending on the url of the page
  const language = {
    locale: !pageUrl.includes('/en/') ? 'is_IS' : 'en_US'
  }

  const isIslandic = language.locale === 'is_IS' ? true : false;

  //this object gets its values depending on the language
  const componentText = {
    title: isIslandic ? 'Afsakið, við fundum ekki þessa síðu.' : 'Sorry, we couldn’t find that page.',
    subtitle: isIslandic ? 'Síðan sem þú baðst um er ekki til.' : 'The page you’re searching doesn’t exist.',
    btnText: isIslandic ? 'Fara á forsíðu' : 'Go to home',
    link: isIslandic ? '/' : '/en'
  }

  return (
    <>
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <SEO title="404: Not Found" />
      <div className="page404 content-wrapper">
        <img className="page404__image" src={SvgElement} alt="404 not found" />
        <h3 className="page404__title">{componentText.title}</h3>
        <p className="page404__subtitle subtitle">{componentText.subtitle}</p>
        <Link className="page404__link cta-with-pseudo" to={componentText.link}>{componentText.btnText}</Link>
      </div>
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}

